import {
  Box,
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { faGlobe } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t, Trans } from '@lingui/macro'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { LocalePickerList } from 'modules/i18n/components/LocalePickerList'
import { SUPPORTED_LOCALE_OPTIONS } from 'modules/i18n/constants'
import { useLinguiLocale } from 'modules/i18n/hooks/useLinguiLocale'
import { useGetLocalizedUrlForThisPageIfAvailable } from 'modules/marketing_pages/hooks/useGetLocalizedUrlForThisPageIfAvailable'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

import {
  COLOR_SCHEME,
  LINK_COLOR,
  LINK_COLOR_HOVER,
} from './Header/colorConstants'
import { useMarketingHeaderUIContext } from './Header/MarketingHeaderUIContext'

export const MarketingLanguageSwitcher = ({
  forceMode,
}: {
  forceMode?: 'dark' | 'light'
}) => {
  const { mode } = useMarketingHeaderUIContext()

  const modeToUse = forceMode || mode
  const { isOpen, onOpen, onClose } = useDisclosure()

  const getUrl = useGetLocalizedUrlForThisPageIfAvailable()
  const { replace } = useRouter()
  // The latest known value of your user setting locale
  const [_lsLocale, setLocalStorageLocale] = useLocalStorage<string | null>(
    USER_SETTINGS_CONSTANTS.gammaLocale,
    null
  )
  const localeKey = useLinguiLocale()

  const handleLocaleClick = useCallback(
    (localeClicked) => {
      const path = getUrl(localeClicked)
      if (path) {
        // No need to set local storage locale here; we will set it in useLinguiInit
        replace(path, undefined, { shallow: false, scroll: false })
      } else {
        setLocalStorageLocale(localeClicked)
        window.location.reload()
      }
    },
    [getUrl, replace, setLocalStorageLocale]
  )

  return (
    <>
      <Button
        w="fit-content"
        onClick={onOpen}
        display={{ base: 'none', md: 'block' }}
        leftIcon={
          <Box display="inline-block">
            <FontAwesomeIcon icon={faGlobe} />
          </Box>
        }
        variant="link"
        textDecoration="none !important"
        alignContent="center"
        lineHeight="base"
        size="sm"
        fontWeight={500}
        color={LINK_COLOR[modeToUse]}
        colorScheme={COLOR_SCHEME[modeToUse]}
        _hover={{
          textDecoration: 'none',
          color: LINK_COLOR_HOVER[modeToUse],
        }}
      >
        {SUPPORTED_LOCALE_OPTIONS[localeKey].displayName}
      </Button>
      {/* Mobile button */}
      <IconButton
        onClick={onOpen}
        display={{ base: 'block', md: 'none' }}
        aria-label={t`Change language`}
        icon={<FontAwesomeIcon icon={faGlobe} />}
        variant="ghost"
        alignContent="center"
        size="md"
        fontWeight={500}
        color={LINK_COLOR[modeToUse]}
        colorScheme={COLOR_SCHEME[modeToUse]}
      />
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          size="xl"
          isCentered
          preserveScrollBarGap={false}
          blockScrollOnMount={false}
        >
          <ModalOverlay />
          <ModalContent m={4}>
            <ModalHeader
              textAlign="center"
              fontSize={{ base: 'xl', md: '3xl' }}
            >
              <Trans>Choose language</Trans>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <LocalePickerList
                selectedLocale={SUPPORTED_LOCALE_OPTIONS[localeKey]}
                onLocaleSelect={(lkey) => {
                  handleLocaleClick(lkey)
                  onClose()
                }}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}
